import { graphql } from 'gatsby'
import React from 'react'
import { useTranslation } from 'react-i18next'

import '../styles/global.css'
import { ContentMain } from '../components/common/content-main'
import { ContentSubPage } from '../components/common/content-sub-page'
import { HeaderTitle } from '../components/common/header-title'
import { CookiesBox } from '../components/cookies-box'
import { Footer } from '../components/footer'
import { Navigation } from '../components/navigation'
import { SEO } from '../components/seo'

const About = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'about-us' })

  return (
    <>
      <SEO title={t('title')} />
      <div className="flex flex-col h-screen justify-between">
        <Navigation />
        <ContentMain>
          <HeaderTitle title={t('title')} />
          <ContentSubPage>
            {[1, 2, 3, 4].map(item => (
              <p key={item} className="mb-3">
                {t(`text-${item}`)}
              </p>
            ))}
          </ContentSubPage>
        </ContentMain>
        <Footer />
      </div>
      <CookiesBox />
    </>
  )
}

export default About

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
